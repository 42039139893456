const validator = require('validator');
const emailjs = require('emailjs-com');
const alertify = require('alertifyjs');

(() => {
  emailjs.init('user_1SqBNhYaPHbhrnf53LizK');
})();

const button = document.getElementById('form-submit');

function sendEmail(e) {
  e.preventDefault();
  const {
    default: { isEmail, isEmpty },
  } = validator;

  const form = document.forms['contact'];

  const name = form['name'].value;
  const email = form['email'].value;
  const message = form['message'].value;

  const valid = isEmail(email) && !isEmpty(name) && !isEmpty(message);

  if (valid) {
    emailjs
      .send('service_qf082ms', 'template_y84xuns', {
        from_name: name,
        reply_to: email,
        message,
      })
      .then(
        () => {
          form['name'].value = '';
          form['email'].value = '';
          form['message'].value = '';
          alertify.set('notifier', 'position', 'top-right');
          alertify.success('Pitanje poslato!', 1.5);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  }
}

// attach on click handler to the button
button.onclick = sendEmail;
